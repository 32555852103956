import Head from 'next/head'

import Layout from 'components/Layout'
import { ErrorState } from 'components/ErrorState'

export const ErrorNotFound = () => {
  return (
    <>
      <Layout
        sidebar={() => null}
        hideSidebar
        bgClassName="bg-default"
        className="flex items-center justify-center"
      >
        <Head>
          <title>
            Ruang Kolaborasi Merdeka Mengajar - Halaman Tidak Ditemukan
          </title>
        </Head>

        <ErrorState
          icon="highlight_alt"
          title="Halaman Tidak Ditemukan"
          description="Periksa kembali tautan Anda atau kembali ke halaman utama untuk
              mengakses menu lainnya."
          actionLink="/"
          actionText="Kembali ke Halaman Utama"
        />
      </Layout>
    </>
  )
}

export default ErrorNotFound
